import { _js } from '@ifixit/localize';
import { Utils } from 'Shared/utils';
import { MediaItemData } from '../MediaLibrary/item';
import { Modal } from 'Shared/modal';
import { FrameModules } from 'Shared/frame_modules';
import StrictObject from 'strict-object';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

/**
 * The createCropper() function is where most of the magic happens here. The
 * most tricky part is attempting to snap to an exact 4:3 or 1:1 when
 * necessary.
 *
 * The data structures here are tightly integrated with ImageLib::prepareImage().
 *
 * @author Dave
 */

FrameModules.add('ImageCropFrameModule', () => {
   Modal.addEvents({
      onImageCropLoad: function (contentBox, options, responseData) {
         ImageCrop.initialize(contentBox, options, responseData);
      },
      onImageCropDisplay: function (options) {
         ImageCrop.display(options);
      },
      onImageCropUnload: function () {
         ImageCrop._unload();
      },
   });

   window.ImageCrop = {
      /* Thumbnail display dimensions */
      thumb: {
         width: 592,
         height: 444,
      },

      /* Default minimum allowed dimensions */
      options: {
         min: {
            width: 50,
            height: 50,
         },
      },

      /* Current dimensions, updated as the user drags the cropper */
      dimensions: {},

      /* Events broadcaster that is thrown away when the dialog is unloaded */
      events: null,

      /**
       * Show the cropping dialog for the given mediaItem using the specifications
       * from the given mediaTarget
       *
       * When cropping is complete, callback() is called. If cropping fails or is
       * canceled, callback() is called with no parameters.
       */
      showForMediaItem: function (mediaItem, mediaTarget, callback) {
         let mediaItemFilterName = mediaTarget && mediaTarget.getMediaFilterName();

         // ImageCropFrameModule.php gets passed along the media_filter_name
         // which is set in a data-attribute on the MediaTarget. This is a problem
         // if the user changes to the image tab and tries to crop an image.
         // This hack fixes this.
         if (mediaItemFilterName == 'guide_step_video') {
            mediaItemFilterName = 'guide_step_image';
         }

         Modal.open({
            type: 'module',
            name: 'ImageCrop',
            boxClass: 'mediaLibraryModalBox cropContainer',
            serverOptions: {
               imageid: mediaItem.getID(),
               accepts: mediaItemFilterName,
            },
            clientOptions: {
               mediaItem: mediaItem,
               mediaItemFilterName: mediaItemFilterName,
               events: {
                  complete: function cropComplete(mediaItem) {
                     if (callback) {
                        callback(mediaItem);
                     }
                  },
               },
            },
         });
      },

      initialize: function (contentBox, clientOptions, responseData) {
         // Create an event broadcaster that will be thrown away when the
         // dialog is unloaded.
         this.events = new Events();
         this.events.addEvents(clientOptions.events);

         this.mediaImage = clientOptions.mediaItem;
         this.sourceImageData = new MediaItemData(responseData.sourceImage);
         this.suggestedCrop = responseData.suggestedCrop;
         this.imageData = new MediaItemData(responseData.image);
         // The image data provided by the crop dialog request has markup and markup_string,
         // which we'll need for cropping if the image has markers on it already.
         // Preserves the filter_state, if it exists, which is required to re-open the crop
         // dialogue if it is closed without saving, or the crop is unsuccessful.
         this.mediaImage.setDataCheckFilterState(this.imageData);

         let filterInfo = (this.filter = new ImageCropFilter(responseData.filterInfo));
         if (filterInfo.width()) {
            this.options.min = {
               width: filterInfo.width(),
               height: filterInfo.height(),
            };
         }
         this.clientOptions = clientOptions || {};
         let data = this.mediaImage.data;

         this.container = $('imageCropContainer');

         /* The status display elements */
         new ImageCropDimensions(this.events, $(document));

         this.addClickEvents();
         this.attachImage(this.sourceImageData);
      },

      display: function () {
         if (!this.cropper) {
            this.createCropper(this.sourceImageData);
         }
      },

      addClickEvents: function () {
         $('imageCropButton')
            .removeEvents()
            .addEvent('click', () => {
               this.save();
            });
         clickSafe($('cropCloseBtn'), () => {
            Modal.cancel();
         });
      },

      attachImage: function (data) {
         let src = this.mediaImage.getUncroppedPreview('medium');

         const img = new Element('img', { src: src, class: 'img' });
         img.set({
            id: 'cropImage',
         }).setStyles({
            width: data.scaled_width(),
            height: data.scaled_height(),
         });

         this.container.empty().adopt(img);

         this.updateDimensions({
            original_width: data.width(),
            original_height: data.height(),
         });

         this.container.setStyles({
            height: data.scaled_height(),
         });
      },

      createCropper: function (sourceImageData) {
         let self = this;
         let imageData = this.imageData;
         let thumb = {
            width: sourceImageData.scaled_width(),
            height: sourceImageData.scaled_height(),
         };

         // set the starting position and dimensions
         let startWidth, startHeight, startTop, startLeft;
         let markup = this.suggestedCrop || imageData.markup();
         let crop = markup ? markup.crop : null;

         if (crop) {
            startWidth = crop.size.width;
            startHeight = crop.size.height;
            startTop = crop.from.y;
            startLeft = crop.from.x;
         } else {
            startWidth = sourceImageData.width();
            startHeight = sourceImageData.height();
            startTop = 0;
            startLeft = 0;
         }

         this.updateDimensions({
            top: startTop,
            left: startLeft,
            width: startWidth,
            height: startHeight,
         });

         // create the cropper
         let ratio = sourceImageData.width() / thumb.width;
         let ratioName = this.filter.ratio();

         const aspectRatio =
            ratioName === 'FOUR_THREE' ? 4 / 3 : ratioName === 'ONE_ONE' ? 1 : Number.NaN;

         this.cropper = new Cropper($('cropImage'), {
            viewMode: 1,
            dragMode: 'move',
            movable: false,
            zoomable: false,
            rotatable: false,
            background: false,
            autoCrop: false,
            checkOrientation: false,

            aspectRatio: aspectRatio,

            minCropBoxWidth: Math.max(
               Math.ceil((this.options.min.width / sourceImageData.width()) * thumb.width),
               100
            ),
            minCropBoxHeight: Math.max(
               Math.ceil((this.options.min.height / sourceImageData.height()) * thumb.height),
               100
            ),

            ready: () => {
               this.cropper.crop();
               this.cropper.setCropBoxData({
                  top: Math.round((startTop / sourceImageData.height()) * thumb.height),
                  left: Math.round((startLeft / sourceImageData.width()) * thumb.width),
                  width: Math.ceil((startWidth / sourceImageData.width()) * thumb.width),
                  height: Math.ceil((startHeight / sourceImageData.height()) * thumb.height),
               });
            },

            crop: event => {
               updateCropRegion(event.detail);
            },
         });

         function updateCropRegion(crop) {
            let image_top = Math.round(ratio * crop.y);
            let image_left = Math.round(ratio * crop.x);
            let image_width = Math.round(ratio * crop.width);
            let image_height = Math.round(ratio * crop.height);

            // Snap to 4:3
            if (ratioName == 'FOUR_THREE') {
               image_width -= image_width % 4;
               image_height = image_width * 0.75;

               if (image_width < self.options.min.width || image_height < self.options.min.height) {
                  image_width = self.options.min.width;
                  image_height = self.options.min.height;
               }
            }

            // Snap to 1:1
            if (ratioName == 'ONE_ONE') {
               image_width = Math.min(image_width, image_height);
               image_height = image_width;
            }

            // Snap to edges
            if (image_width > sourceImageData.width()) {
               image_left = 0;
               image_width = sourceImageData.width();
            }
            if (image_height > sourceImageData.height()) {
               image_top = 0;
               image_height = sourceImageData.height();
            }

            self.updateDimensions({
               top: image_top,
               left: image_left,
               width: image_width,
               height: image_height,
            });
         }

         updateCropRegion(this.cropper.getData());
      },

      updateDimensions: function (newDimensions) {
         Object.append(this.dimensions, newDimensions);
         this.events.fireEvent('changeDimensions', [this.dimensions]);
      },

      getMarkupString: function () {
         let markup = this.getMarkup();
         let markupString = ';';

         // crop and enhance
         markupString +=
            'crop,' +
            markup.crop.from.x +
            'x' +
            markup.crop.from.y +
            ',' +
            markup.crop.size.width +
            'x' +
            markup.crop.size.height +
            ';' +
            (markup.enhance ? 'enhance;' : '');

         // Append the existing marker string if there was one so the markers
         // aren't removed after cropping.
         return markupString + this.mediaImage.markersString();
      },

      getMarkup: function () {
         let pos = this.dimensions;
         let markup = {};

         markup.crop = {
            from: {
               x: pos.left,
               y: pos.top,
            },
            size: {
               width: pos.width,
               height: pos.height,
            },
         };

         return markup;
      },

      save: function () {
         let self = this;
         let mediaImage = this.mediaImage;
         let markup = this.getMarkupString();
         let data = new Future();

         Modal.lock().loading(_js('Saving your changes...'));

         mediaImage.setDataPromise(data);
         // Initiate the crop
         new Request.AjaxIO('cropImage', {
            onSuccess: successfulCropRequest.bind(this),
            onError: (response => {
               cropFail(response.error);
               closeDialog();
            }).bind(this),
         }).send(mediaImage.getID(), markup, this.filter.ratio());

         // Cropping successfully initiated.
         function successfulCropRequest(jobID) {
            finishedCropping();

            // Now just ping until the image is ready.
            new Request.AjaxIO('cropImageStatus', {
               timeout: 60_000, // 1 minute
               onComplete: cropJobSucceededOrErrored,
               onTimeout: () => cropFail(_js('Image processing timed out')),
            }).ping(
               jobID,
               /* returnMediaItemData = */ true,
               self.clientOptions.mediaItemFilterName
            );

            closeDialog();
         }

         // Cropping complete - all images available
         function cropJobSucceededOrErrored(image) {
            if (image.error) {
               cropFail(image.error);
            } else {
               data.resolve(image);
            }
         }

         function cropFail(error) {
            data.error(error);
         }

         function closeDialog() {
            Modal.doneLoading().unlock().pop();
         }

         function finishedCropping() {
            /*
             * This needs to happen before we're finished so the mediaItem is in
             * a loading state.
             * Having a filter_state property will make the media item in the
             * UI clickable during the loading state.
             */
            mediaImage.removeFilterState();
            self._finished(self.mediaItem);
         }
      },

      /**
       * Called by Modal when the crop dialog is closed
       *
       * We have to do some manual destruction here because this is a singleton
       * and callers may install event handlers to the cropper each time it's
       * shown.
       */
      _unload: function () {
         this.cropper.destroy();
         this.cropper = null;
         this._finished();
      },

      _finished: function (mediaItem) {
         if (this.events) {
            this.events.fireEvent('complete', mediaItem ? [mediaItem] : null);
            delete this.events;
         }
      },
   };

   Object.append(ImageCrop, Utils.EventsFunctions);

   // eslint-disable-next-line no-var
   var ImageCropFilter = StrictObject.define(['ratio', 'width', 'height']);

   /**
    * A class that handles updating the display of crop dimensions
    */
   // eslint-disable-next-line no-var
   var ImageCropDimensions = new Class({
      initialize: function (listenTo, parentElement) {
         function getChild(selector) {
            return parentElement.getElement(selector);
         }

         let elements = {
            top: getChild('.image_top'),
            left: getChild('.image_left'),
            width: getChild('.image_width'),
            height: getChild('.image_height'),

            original_width: getChild('.original_width'),
            original_height: getChild('.original_height'),
         };

         listenTo.addEvent('changeDimensions', updateDimensions);

         function updateDimensions(dimensions) {
            Object.each(dimensions, (value, key) => {
               elements[key].set('html', value);
            });
         }
      },
   });
});
